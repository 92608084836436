import React, { useEffect, useRef } from 'react';
import styles from './ConnectionPanel.module.css';

const ConnectionPanel = ({
  socketConnected,
  inputThreadId,
  setInputThreadId,
  instructText,
  setInstructText,
  prependText,
  setPrependText,
  appendText,
  setAppendText,
  interfaceType,
  personas = [],
  whoIndex,
  setWhoIndex,
}) => {
  const instructionsRef = useRef(null);
  const prependRef = useRef(null);
  const appendRef = useRef(null);

  useEffect(() => {
    if (instructionsRef.current) instructionsRef.current.innerText = instructText;
    if (prependRef.current) prependRef.current.innerText = prependText;
    if (appendRef.current) appendRef.current.innerText = appendText;
  }, [instructText, prependText, appendText]);

  const handleBlur = (ref, setState) => {
    if (ref.current) {
      setState(ref.current.innerText);
    }
  };

  const renderInterfaceSpecificContent = () => {
    if (interfaceType === 'chat') {
      return (
        <div className={styles.cardsContainer}>
          {personas.map((persona, index) => (
            <div
              key={index}
              className={`${styles.personaCard} ${
                whoIndex === index ? styles.highlightedPersona : ''
              }`}
              onClick={() => setWhoIndex(index)}
            >
              <img
                src={persona.avatar}
                alt={`${persona.name}'s avatar`}
                className={styles.personaAvatar}
              />
              <div className={styles.personaContent}>
                <div className={styles.title}>
                  <strong>{persona.name}</strong> ({persona.career})
                </div>
                <p>{persona.detail}</p>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <>
        <div className={styles.textLabel}>Thread ID</div>
        <input
          type="text"
          placeholder="Thread ID"
          value={inputThreadId}
          onChange={(e) => setInputThreadId(e.target.value)}
          disabled={socketConnected}
          className={styles.input}
        />

        <div className={styles.textLabel}>Core Instructions</div>
        <div
          contentEditable
          className={styles.instructionsEditable}
          ref={instructionsRef}
          onBlur={() => handleBlur(instructionsRef, setInstructText)}
          suppressContentEditableWarning={true}
        />

        <div className={styles.textLabel}>Enhance Background</div>
        <div
          contentEditable
          className={styles.instructionsEditable}
          ref={prependRef}
          onBlur={() => handleBlur(prependRef, setPrependText)}
          suppressContentEditableWarning={true}
        />

        <div className={styles.textLabel}>Nuance High-level Guidance</div>
        <div
          contentEditable
          className={styles.instructionsEditable}
          ref={appendRef}
          onBlur={() => handleBlur(appendRef, setAppendText)}
          suppressContentEditableWarning={true}
        />
      </>
    );
  };

  return <div className={styles.connectionControls}>{renderInterfaceSpecificContent()}</div>;
};

export default ConnectionPanel;
