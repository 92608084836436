import React, { useState, useRef, useEffect } from 'react';
import ChatProcessor from './ChatProcessor';
import ShowGroup from './ShowGroup';
import ConnectionPanel from './ConnectionPanel';
import ConnectAction from './ConnectAction';
import Connection from './Connection';
import styles from './App.module.css';

function App() {
  const [activeTab, setActiveTab] = useState('connection'); // Default tab
  const [threadId, setThreadId] = useState('');
  const [socketConnected, setSocketConnected] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [prependText, setPrependText] = useState('');
  const [appendText, setAppendText] = useState('');
  const [instructText, setInstructText] = useState('');
  const [whoGroup, setWhoGroup] = useState(null);
  const [whoIndex, setWhoIndex] = useState(null);
  const [whoTalk, setWhoTalk] = useState(null);
  const [whoAvatar, setWhoAvatar] = useState(null);
  const [showType, setShowType] = useState(null);
  const [interfaceType, setInterfaceType] = useState('');
  const socketRef = useRef(null);
  const [config, setConfig] = useState(null);

  // Fetch config.json with epoch time to prevent caching
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch(`/config.json?timestamp=${Date.now()}`);
        const data = await response.json();

        setConfig(data.main);
        setInstructText(data.main.connection.instruct);
        setPrependText(data.main.connection.prepend);
        setAppendText(data.main.connection.append);
        setShowType(data.main.settings.showtype);
        setInterfaceType(data.main.settings.interface);

        const { group } = data.main.personas;
        setWhoGroup(data.main.personas);

        if (whoIndex === null && group.length > 0) {
          setWhoIndex(Math.floor(Math.random() * group.length));
        }
      } catch (error) {
        console.error('Error fetching config.json:', error);
      }
    };

    fetchConfig();
  }, []);

  // Update whoTalk and whoAvatar whenever whoIndex changes
  useEffect(() => {
    if (whoGroup && whoIndex !== null && whoGroup.group[whoIndex]) {
      setWhoTalk(whoGroup.group[whoIndex].name);
      setWhoAvatar(whoGroup.group[whoIndex].avatar);
    }
  }, [whoIndex, whoGroup]);

  const connectSocket = () => window.connectSocket();
  const disconnectSocket = () => window.disconnectSocket();

  return (
    <div className={styles.appWrapper}>
      <header className={styles.header}>
        {config && <div className={styles.title}>{config.settings.title}</div>}
      </header>

      <div className={styles.contentWrapper}>
        {/* Left Column */}
        <div className={styles.leftColumn}>
          <div className={styles.tabMenu}>
            <button
              className={`${styles.tabButton} ${
                activeTab === 'connection' ? styles.activeTab : ''
              }`}
              onClick={() => setActiveTab('connection')}
            >
              Details
            </button>
            <button
              className={`${styles.tabButton} ${
                activeTab === 'showgroup' ? styles.activeTab : ''
              }`}
              onClick={() => setActiveTab('showgroup')}
            >
              Show Group
            </button>
          </div>
          <div className={styles.tabContent}>
            {activeTab === 'connection' ? (
              <ConnectionPanel
                socketConnected={socketConnected}
                inputThreadId={threadId}
                setInputThreadId={setThreadId}
                instructText={instructText}
                setInstructText={setInstructText}
                prependText={prependText}
                setPrependText={setPrependText}
                appendText={appendText}
                setAppendText={setAppendText}
                connectSocket={connectSocket}
                disconnectSocket={disconnectSocket}
                interfaceType={interfaceType}
                personas={config && config.personas ? config.personas.group : []}
                whoIndex={whoIndex}
                setWhoIndex={setWhoIndex}
              />
            ) : (
              <ShowGroup
                personas={whoGroup}
                whoIndex={whoIndex}
                setWhoIndex={setWhoIndex}
                showOnlyConnectButton={interfaceType === 'chat'}
              />
            )}
          </div>
          <ConnectAction
            socketConnected={socketConnected}
            connectSocket={connectSocket}
            disconnectSocket={disconnectSocket}
            interfaceType={interfaceType}
          />
        </div>

        {/* Center Column */}
        <div className={styles.centerColumn}>
          {socketConnected && (
            <ChatProcessor
              socketRef={socketRef}
              prependText={prependText}
              appendText={appendText}
              whoTalk={whoTalk}
              whoAvatar={whoAvatar}
              isStreaming={isStreaming}
              handleStreamedMessage={(data) => setIsStreaming(true)}
              completeStreaming={() => setIsStreaming(false)}
            />
          )}
        </div>

        {/* Right Column */}
        <div className={styles.rightColumn}>
          {/* Placeholder for right column content */}
        </div>
      </div>

      <Connection
        setThreadId={setThreadId}
        setSocketConnected={setSocketConnected}
        setSocketRef={(socket) => {
          socketRef.current = socket;
        }}
        handleStreamedMessage={(data) => setIsStreaming(true)}
        completeStreaming={() => setIsStreaming(false)}
        instructText={instructText}
        prependText={prependText}
        appendText={appendText}
      />
    </div>
  );
}

export default App;
