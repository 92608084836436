import React, { useState, useRef, useEffect } from 'react';
import styles from './Chat.module.css';

function Chat({ messages, sendMessage, showPlaceholder }) {
  const [messageInput, setMessageInput] = useState('');
  const chatContainerRef = useRef(null);
  let lastWhoSpeaks = '';
  let lastWhoAvatar = '';

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSend = () => {
    if (messageInput.trim()) {
      sendMessage(messageInput);
      setMessageInput('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className={styles.chatWrapper}>
      <div className={styles.chatContainer} ref={chatContainerRef}>
        {messages.map((msg, idx) => (
          <div key={idx} className={styles.messageContainer}>
            <div
              className={`${styles.message} ${msg.role === 'assistant' ? styles.assistantMessage : styles.userMessage}`}
            >
              {msg.role === 'assistant' ? (
                <div className={styles.avatarHolder}>
                  <img className={styles.responseAvatar} src={lastWhoAvatar} alt="User Avatar" />
                  {lastWhoSpeaks}
                </div>
              ) : (
                (lastWhoSpeaks = msg.whoSpeaks, lastWhoAvatar = msg.whoAvatar) && null
              )}
              <div dangerouslySetInnerHTML={{ __html: msg.content }} />
            </div>
          </div>
        ))}

        {/* Render the placeholder bubble if showPlaceholder is true */}
        {showPlaceholder && (
          <div className={styles.messageContainer}>
            <div className={`${styles.message} ${styles.assistantMessage} ${styles.breathingEffect} ${styles.placeholder}`}>
              <div className={styles.avatarHolder}>
                <img className={styles.responseAvatar} src={lastWhoAvatar} alt="User Avatar" />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={styles.inputContainer}>
        <textarea
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Learn more about each career, just ask..."
          className={styles.inputField}
          rows="1"
        />
        <button onClick={handleSend} className={styles.sendButton}>
          Ask
        </button>
      </div>
    </div>
  );
}

export default Chat;
