import React, { useState, useEffect, useRef } from 'react';
import Chat from './Chat';

function ChatProcessor({ socketRef, prependText, appendText, whoTalk, whoAvatar, isStreaming, handleStreamedMessage, completeStreaming }) {
  const [messages, setMessages] = useState([]);
  const [showPlaceholder, setShowPlaceholder] = useState(false); // Track placeholder visibility

  const previousChunkRef = useRef('');
  const currentStreamedDivRef = useRef(null);

  const scrollToBottom = (chatContainerRef) => {
    if (chatContainerRef && chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.on('message', (data) => {
        // Hide the placeholder bubble as soon as a real response is received
        setShowPlaceholder(false);

        if (data.role === 'assistant') {
          const newChunk = data.content;
          if (newChunk !== previousChunkRef.current) {
            previousChunkRef.current = newChunk;

            setMessages((prevMessages) => {
              const lastMessage = prevMessages[prevMessages.length - 1];

              // Append the new chunk to the last assistant message
              if (lastMessage?.role === 'assistant') {
                return prevMessages.map((msg, idx) =>
                  idx === prevMessages.length - 1
                    ? { ...msg, content: msg.content + newChunk }
                    : msg
                );
              }

              return [...prevMessages, { role: 'assistant', content: newChunk }];
            });
          }
        } else {
          setMessages((prevMessages) => [
            ...prevMessages,
            { ...data, whoSpeaks: data.whoTalk, whoAvatar: data.whoAvatar }
          ]);
        }
        scrollToBottom(currentStreamedDivRef);
      });

      socketRef.current.on('streaming_end', () => {
        currentStreamedDivRef.current = null;
        previousChunkRef.current = '';
        completeStreaming();
      });
    }
  }, [socketRef, completeStreaming]);

  const sendMessage = (messageInput) => {
    if (!messageInput.trim() || isStreaming || !socketRef.current) return;

    // Show the placeholder bubble immediately after sending a message
    setShowPlaceholder(true);
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'user', content: messageInput, whoSpeaks: whoTalk, whoAvatar: whoAvatar }
    ]);

    const finalMessage = `${prependText ? prependText + ' ' : ''} Ask ${whoTalk}: ${messageInput}${appendText ? ' ' + appendText : ''}`;
    socketRef.current.emit('message', {
      content: finalMessage
    });
  };

  return <Chat messages={messages} sendMessage={sendMessage} showPlaceholder={showPlaceholder} />;
}

export default ChatProcessor;
