import React, { useCallback } from 'react';
import styles from './ShowGroup.module.css';

// Local debounce utility function
function debounce(func, delay) {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer); // Clear previous timer
    timer = setTimeout(() => {
      func(...args); // Execute the function after the delay
    }, delay);
  };
}

function ShowGroup({ personas, whoIndex, setWhoIndex, showOnlyConnectButton }) {
  // Create a debounced version of setWhoIndex
  const debouncedSetWhoIndex = useCallback(
    debounce((index) => {
      console.log("Debounced Avatar clicked, setting whoIndex:", index);
      setWhoIndex(index); // Update the selected avatar index
    }, 200),
    [setWhoIndex]
  );

  return (
    <div
      className={`${styles.showGroupContainer} ${
        showOnlyConnectButton && whoIndex !== null ? styles.highlightedPersona : ""
      }`}
    >
      {personas.group.map((persona, index) => (
        <div
          key={index}
          className={styles.avatarContainer}
          style={{
            filter: index === whoIndex ? "none" : "grayscale(80%)", // Highlight the selected avatar
          }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent event bubbling
            debouncedSetWhoIndex(index); // Use debounced function
          }}
        >
          <img src={persona.avatar} alt={persona.name} className={styles.avatarImage} />
          <p><b>{persona.name}</b><br/><span className={styles.avatarCareer}>({persona.career})</span></p>

        </div>
      ))}
    </div>
  );
}

export default ShowGroup;
