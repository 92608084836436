import React, { useEffect, useRef } from 'react';
import { io } from 'socket.io-client';

const path = process.env.REACT_APP_SOCKET_PATH || '/socket.io';

const Connection = ({
  setSocketRef,
  setSocketConnected,
  setThreadId,
  handleStreamedMessage,
  completeStreaming,
  instructText,
  prependText,
  appendText,
}) => {
  const socketRef = useRef(null);

  const connectSocket = () => {
    if (socketRef.current) return;

    const rootServer = process.env.REACT_APP_ROOT_SERVER;

    socketRef.current = io(rootServer, {
      path: path,
      transports: ['websocket'],
      withCredentials: false,
    });

    socketRef.current.on('connect', () => {
      setSocketConnected(true);
      setSocketRef(socketRef.current);

      socketRef.current.emit('initialize', {
        threadId: null,
        assistantId: process.env.REACT_APP_ASSISTANT_ID,
        instructions: instructText,
        prepend: prependText,
        append: appendText,
      });

      socketRef.current.on('threadId', (data) => setThreadId(data.threadId));
      socketRef.current.on('message', handleStreamedMessage);
      socketRef.current.on('streaming_end', completeStreaming);
    });

    socketRef.current.on('disconnect', () => {
      setSocketConnected(false);
    });
  };

  const disconnectSocket = () => {
    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current = null;
      setSocketConnected(false);
    }
  };

  useEffect(() => {
    window.connectSocket = connectSocket;
    window.disconnectSocket = disconnectSocket;
  }, []);

  return null;
};

export default Connection;
