import React from 'react';
import styles from './ConnectAction.module.css';

const ConnectAction = ({
  socketConnected,
  connectSocket,
  disconnectSocket,
  interfaceType,
}) => {
  return (
    <div className={styles.actionContainer}>
      {interfaceType === "chat" ? (
        !socketConnected ? (
          <button
            onClick={connectSocket}
            className={`${styles.connectButton} ${styles.fullWidthButton}`}
          >
            Start Your Career Talk
          </button>
        ) : null
      ) : (
        <div className={styles.buttonContainer}>
          <button
            onClick={connectSocket}
            disabled={socketConnected}
            className={styles.connectButton}
          >
            Connect
          </button>
          <button
            onClick={disconnectSocket}
            disabled={!socketConnected}
            className={styles.disconnectButton}
          >
            Disconnect
          </button>
        </div>
      )}
    </div>
  );
};

export default ConnectAction;
